export interface EnvironmentModel {
  production: boolean;
  environment: Environment;
  api_url: string;
  allowedDomains: string[];
  disallowedRoutes: string[];
}

export enum Environment {
  Production,
  Staging,
  Development,
  Local
}
