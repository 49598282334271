import { Injectable } from '@angular/core';
import { Token, User } from '@app/helpers/auth.helper';
import { LoginResponse, LoginRequest, RegisterRequest, DecodedToken } from '@app/models/account.model';
import { GlobalResponse } from '@app/models/response.model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ApiService } from './api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthRoutes } from '@app/helpers/routes.helper';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  pattern = '^.*(?=.{7,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#@:;/.,<>|`~*()$%&?"]).*$';

  private user = new BehaviorSubject<LoginResponse | null>(null);
  private roles = new BehaviorSubject<string[]>([]);

  user$ = this.user.asObservable().pipe(
    map(user => {
      if (user) {
        return user;
      }
      return this.getUser() as LoginResponse;
    })
  );

  roles$ = this.roles.asObservable().pipe(
    map(roles => {
      if (roles.length) {
        return roles;
      }
      return this.getRoles();
    })
  );
  constructor(
    private api: ApiService,
    private jwt: JwtHelperService,
    private router: Router
  ) { }

  register(request: RegisterRequest): Observable<GlobalResponse> {
    const formData = new FormData();
    formData.append('name', request.name);
    formData.append('userFirstName', request.userFirstName);
    formData.append('userLastName', request.userLastName);
    formData.append('address', request.address);
    formData.append('phone', request.phone);
    formData.append('password', request.password);
    formData.append('logo', request.logo as File);
    formData.append('email', request.email);
    return this.api.postFile<GlobalResponse>(AuthRoutes.Register, formData);
  }

  login(request: LoginRequest): Observable<LoginResponse> {
    return this.api.post<LoginResponse>(AuthRoutes.Login, request).pipe(
      map(resp => {
        resp.staffImageUrl = resp.staffImageUrl ? resp.staffImageUrl : '';
        return resp;
      }),
      tap(response => {
        if (!response.resetPassword) {
          sessionStorage.setItem(Token, response.token);
          sessionStorage.setItem(User, btoa(JSON.stringify(response)));
          this.updateUser(response);
          this.setRoles(response.token);
        }
      })
    );
  }

  isLoggedIn(): boolean {
    return !this.jwt.isTokenExpired();
  }

  logout(): void {
    sessionStorage.removeItem(User);
    sessionStorage.removeItem(Token);
    this.updateUser(null);
    this.router.navigate([`/auth/login`]);
  }

  private getUser(): LoginResponse | null {
    const sessionUser = sessionStorage.getItem(User);
    if (sessionUser) {
      const decoded = atob(sessionUser);
      return JSON.parse(decoded) as LoginResponse;
    }
    return null;
  }

  private getRoles(): string[] {
    const sessionUser = sessionStorage.getItem(User);
    if (sessionUser) {
      const decoded = atob(sessionUser);
      const { token } = JSON.parse(decoded) as LoginResponse;
      const decodedToken = this.jwt.decodeToken(token) as DecodedToken;
      const { role } = decodedToken;
      return Array.isArray(role) ? role : [role];
    }
    return [];
  }

  updateUser(user: LoginResponse | null): void {
    this.user.next(user);
  }

  setRoles(token: string) {
    const decodedToken = this.jwt.decodeToken(token) as DecodedToken;
    const { role } = decodedToken;
    if (Array.isArray(role)) {
      this.roles.next(role)
    } else {
      this.roles.next([role])
    }
  }

  goToDashboard(): void {
    this.router.navigate([`/home`]);
  }
}
